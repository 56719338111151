<template>
  <div class="hello">
    <div id='loader'>
      <div class='spinner'>
        <div class='dot1' />
        <div class='dot2' />
      </div>
    </div>

    <!-- Full Body Container -->



    <!-- Start Main Slider -->
    <!-- Carousel -->
    <!-- Carousel inner -->
    <section id="main-slider" >
      
      <carousel :per-page="1" :autoplay="true" >
            <slide v-for="(n,index) in HeadersImgs" v-bind:key="index">    
                    <v-img :src="n" aspect-ratio="1" cover height="700"/>
            </slide>
          </carousel>

    </section>




    <!-- /carousel -->

    <!-- End Main Slider -->

    <section class="service section">
      <div class="container">
        <div class="row">
          <div class="col-sm-8 col-xs-12 col-sm-offset-2 col-xs-offset-0">
            <div class="section-heading">
              <header>
                <h1 class="title"><span>Unique Products To</span> Elevate Your Business</h1>
                <h2>
                  <p>All through the power of automation…</p>
                </h2>
              </header>
            </div>
          </div>
        </div> <!-- /.row -->
        <div class="row">
          <div class="col-md-4 col-sm-6 service-box" data-animation="fadeIn" data-animation-delay="01">
            <div class="service-icon">
              <!-- <i class="flaticon-responsive icon-large"></i> -->
              <img class="flaticon-img icon-large"
                src="https://firebasestorage.googleapis.com/v0/b/websiteincubefzco.appspot.com/o/Others%2F2.png?alt=media&token=4ac39f3a-edaa-47a3-9843-e32a356eca23"
                alt="Icon">
            </div>
            <div class="service-content">
              <a href="#">
                <h4></h4>
              </a>
              <h4>
                <p>Higher Return On Investment</p>
              </h4>
            </div>
          </div> <!-- /.service-box -->
          <div class="col-md-4 col-sm-6 service-box" data-animation="fadeIn" data-animation-delay="01">
            <div class="service-icon">
              <!-- <i class="flaticon-sofa-of-one-place-outline icon-large"></i> -->
              <img class="flaticon-img icon-large"
                src="https://firebasestorage.googleapis.com/v0/b/websiteincubefzco.appspot.com/o/Others%2F3.png?alt=media&token=0d5c38ce-9df0-47e1-9222-8a082837244e"
                alt="Icon">
            </div>
            <div class="service-content">
              <a href="#">
                <h4></h4>
              </a>
              <h4>
                <p>Enhance Customer Satisfaction</p>
              </h4>
            </div>
          </div> <!-- /.service-box -->
          <div class="col-md-4 col-sm-6 service-box" data-animation="fadeIn" data-animation-delay="01">
            <div class="service-icon">
              <!-- <i class="flaticon-command icon-large"></i> -->
              <img class="flaticon-img icon-large"
                src="https://firebasestorage.googleapis.com/v0/b/websiteincubefzco.appspot.com/o/Others%2F4.png?alt=media&token=5a8a4b71-2ed5-43ac-8527-97c6a26b6b92"
                alt="Icon">
            </div>
            <div class="service-content">
              <a href="#">
                <h4></h4>
              </a>
              <h4>
                <p>Reduce Product Recall Risk & Reduction Of Expenses</p>
              </h4>
            </div>
          </div> <!-- /.service-box -->
          <div class="col-md-4 col-sm-6 service-box" data-animation="fadeIn" data-animation-delay="01">
            <div class="service-icon">
              <!-- <i class="flaticon-furniture icon-large"></i> -->
              <img class="flaticon-img icon-large"
                src="https://firebasestorage.googleapis.com/v0/b/websiteincubefzco.appspot.com/o/Others%2F5.png?alt=media&token=80239cc2-13c2-4efc-be22-3ecc517370bb"
                alt="Icon">
            </div>
            <div class="service-content">
              <a href="#">
                <h4></h4>
              </a>
              <h4>
                <p>Adaptation To International Standards</p>
              </h4>
            </div>
          </div> <!-- /.service-box -->
          <div class="col-md-4 col-sm-6 service-box" data-animation="fadeIn" data-animation-delay="01">
            <div class="service-icon">
              <!-- <i class="flaticon-sofa icon-large"></i> -->
              <img class="flaticon-img icon-large"
                src="https://firebasestorage.googleapis.com/v0/b/websiteincubefzco.appspot.com/o/Others%2F6.png?alt=media&token=5f0c18c0-d5ec-4b58-ab78-ae44d9906f2b"
                alt="Icon">
            </div>
            <div class="service-content">
              <a href="#">
                <h4></h4>
              </a>
              <h4>
                <p>Mitigate Negative Impact On Brand Image</p>
              </h4>
            </div>
          </div> <!-- /.service-box -->
          <div class="col-md-4 col-sm-6 service-box" data-animation="fadeIn" data-animation-delay="01">
            <div class="service-icon">
              <!-- <i class="flaticon-sofa icon-large"></i> -->
              <img class="flaticon-img icon-large"
                src="https://firebasestorage.googleapis.com/v0/b/websiteincubefzco.appspot.com/o/Others%2F7.png?alt=media&token=fb927667-d4f9-448f-ac25-41e9d70d6b3a"
                alt="Icon">
            </div>
            <div class="service-content">
              <a href="#">
                <h4></h4>
              </a>
              <h4>
                <p>Expand Export Possibilities</p>
              </h4>
            </div>
          </div> <!-- /.service-box -->

        </div> <!-- /.service-box -->
      </div> <!-- /.row -->

      <section class="vid cbiz-bg">
			<div class="container">
				<div class="row">
					<div class="col-xs-12 col-sm-12 col-md-6">
						<div>
							<!-- <div class="video-content">
								<a href="https://www.youtube.com/embed/UC48cE15cNU?autoplay=1" target="_blank" class="video-btn various fancybox.iframe">
									<img src="assets/images/icons/video.png" alt="Video Icon">
								</a>
							</div> -->
              <iframe height="300px" width="100%" src="https://www.youtube.com/embed/UC48cE15cNU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
						</div> <!-- /.section-heading -->
					</div> <!-- /.col- -->
          <div class="col-xs-12 col-sm-12 col-md-6">
            <h3 style="color: white;">Watch The Video</h3>
								<p style="color: white;">INCUBE offers unique best-in-class software, countless top-of-the-line mobile hardware, and the most trustworthy expertise in the technological supply chain and logistics industry. We welcome all to the INCUBE Family as a client, resellers, and partners. </p>

          </div>
				</div> <!-- /.row -->    
			</div> <!-- /.container -->
		</section> <!-- /.vid -->

      <!-- End Services Area -->


      <!-- Our Goal AREA START -->
      <section class='our-goal section cbiz-bg'>
        <div class='container'>
          <div class='section-heading'>
            <center>
              <h1 class='title'><a class='title'>INCUBE’s </a> Reach</h1>
            </center>
            <center>
              <p style="margin-left: 10px;margin-right: 10px;font-size: 1.5em;">INCUBE's years of experience in the supply
                chain and technology,
                gave it the massive reach for knowledge and geographic presence, with well-known references and high level
                of customer satisfaction.
              </p>
            </center>
          </div>
          <div class='row'>
            <div class='col-xs-12 col-sm-12 col-md-6'>
              <div class='carousel goal-slider slide' data-ride='carousel' id='goal-slider'>

                <!-- Wrapper for slides -->

                <div class='item active'>
                  <div class='goal-content'>


                  </div>
                </div> <!-- /.item -->


              </div> <!-- /carousel -->
            </div> <!-- /.col- -->
            <div class='col-xs-12 col-sm-12 col-md-6' />
            <div class='col-xs-12 col-sm-12 col-md-6'>
              <div class='row'>
                <div class='projects'>
                  <div class='col-xs-6'>
                    <div class='pro-item'>
                      <!-- <h2 class='counter'>2,100+</h2> -->
                      <countTo :startVal='0' :endVal='2100' :duration='10000' prefix='+' style="font-size: x-large;">
                      </countTo>
                      <a>
                        <h3 class='achievement'>Customers</h3>
                      </a>
                    </div>
                  </div>
                  <div class='col-xs-6'>
                    <div class='pro-item'>
                      <!-- <center>
                    <h2 class='counter'>18+</h2>
                    </center> -->
                      <countTo :startVal='0' :endVal='18' :duration='10000' prefix='+' style="font-size: x-large;">
                      </countTo>
                      <a>
                        <h3 class='achievement'>Years Track Record</h3>
                      </a>
                    </div>
                  </div>
                  <div class='col-xs-6'>
                    <div class='pro-item'>
                      <!-- <h2 class='counter'>150+</h2> -->
                      <countTo :startVal='0' :endVal='150' :duration='10000' prefix='+' style="font-size: x-large;">
                      </countTo>

                      <a>
                        <h3 class='achievement'>Professionals PHD&#8217;s, MBA&#8217;s, PMP&#8217;s</h3>
                      </a>
                    </div>
                  </div>
                  <div class='col-xs-6'>
                    <div class='pro-item'>
                      <countTo :startVal='0' :endVal='80' :duration='10000' prefix='+' style="font-size: x-large;">
                      </countTo>
                      <!-- <h2 class='counter'>80+</h2> -->
                      <a>
                        <h3 class='achievement'>Locations Worldwide Culturally versatile</h3>
                      </a>
                    </div>
                  </div>

                  <div class='col-xs-12'>
                    <div class='pro-item'>
                      <countTo :startVal='0' :endVal='29000' :duration='10000' prefix='+' style="font-size: x-large;">
                      </countTo>

                      <!-- <h2 class='counter'>29,000+</h2> -->
                      <a>
                        <h3 class='achievement'>Satisfied Users</h3>
                      </a>
                    </div>
                  </div>

                </div> <!-- /.projects -->
              </div> <!-- /.row -->
            </div> <!-- /.col- -->
          </div> <!-- /.row -->
        </div> <!-- /.container -->
      </section> <!-- /.our-goal -->
      <!-- /Our Goal AREA END -->

      <!-- Portfolios Area Start -->
      <section class='portfolios section'>
        <div class='container'>
          <div class='row'>
            <div class='col-sm-8 col-xs-12 col-sm-offset-2 col-xs-offset-0'>
              <div class='section-heading'>
                <h2 class='title'><span>Our</span> Products</h2>
                <p>Check out some of our latest hardware products and top of the line software solutions used by
                  customers.</p>
              </div>
            </div>
          </div> <!-- /.row -->

          <v-row>
            <div class="portfolio-details" style="width: 100%;">
              <div>
                <div class="grid-item col-md-4 col-xs-4" style="min-height: 250px;" v-for="n in Products"
                  v-bind:key="n.id">
                  <div class="portfolio-item">
                    <v-img :src="n.DisplayImage" alt="Gallery" />

                    <div class="port-hover">
                      <div class="port-content">
                        <a>
                          <h3>{{ n.P_Name }}</h3>
                        </a>
                        <p>{{ n.P_Summary }}</p>
                        <a v-if="n.Type === 'Sol'" :href="'/Products/' + n.P_Name">

                          <a  class="port-icon">
                            <img class="hidden-thumbnail" :src="n.DisplayImage" alt="Thumbnail">
                            <img src="assets/images/icons/plus-btn.png" alt="Icon">
                          </a>
                        </a>

                        <a v-else :href="'/Partner/' + n.P_Name">

                          <a  class="port-icon">
                            <img class="hidden-thumbnail" :src="n.DisplayImage" alt="Thumbnail">
                            <img src="assets/images/icons/plus-btn.png" alt="Icon">
                          </a>
                        </a>
                      </div> <!-- /.port-content -->
                    </div> <!-- /.port-hover -->
                  </div><!-- /.portfolio-item -->
                </div> <!-- /.grid-item -->


              </div><!-- /.portfolio-container -->
            </div> <!-- /.portfolio-details -->
          </v-row> <!-- /.row -->
        </div> <!-- /.container -->
      </section> <!-- /.portfolios -->
      <!-- Portfolios Area End -->
      <!-- We Do AREA START -->


      <!-- Celebration AREA START -->
      <section class='full-width-sec' style="background: #26476c;">
        <div class='container'>
          <div class='row'>
            <div class='col-sm-12 col-md-6 pos-static'>
              <div class='cel-feature'>
                <img alt='Img' class='img-responsive'
                  src='https://firebasestorage.googleapis.com/v0/b/websiteincubefzco.appspot.com/o/Others%2FeventImage.jpg?alt=media&token=2e6529fe-069d-4388-8312-d33e50b71b67' 
                  style="max-height: 500px;"/>
              </div>
            </div>
            <div class='col-sm-12 col-md-6 padding-0'>
              <v-col style="height:400px; background: #26476c;">
                <center>

                  <v-row>
                    <v-col class='cel-content'>
                      <h2 class='gen-title' style="text-align: center;">Coming Soon Events</h2>
                    </v-col>
                  </v-row>
                  <v-row  v-for="item in Events" v-bind:key="item.id">
                    <!--                 <v-col >
                  <v-img width="75" height="75" src='https://firebasestorage.googleapis.com/v0/b/incubewebsite.appspot.com/o/Events%2FEvents_Logo%2F1.png?alt=media&token=7c2114fa-0785-4c30-878f-1f18524e0bfd'/>
                </v-col> -->
                    <v-col class='cel-content'>
                      <a>
                        <h4 class='gen-title' style="text-align: center;">{{item.EventTitle}}</h4>
                      </a>
                      <p style="text-align: center;">{{item.date}}</p>
                    </v-col>
                  </v-row>

                </center>



              </v-col>
            </div> <!-- /.col- -->
          </div> <!-- /.row -->
        </div> <!-- /.container -->
      </section> <!-- /.full-width-sec -->
      <!-- /Celebration AREA END -->


      <!-- Recent Blog AREA START -->
      <section class='recent-blog section'>
        <div class='container'>
          <div class='row'>
            <div class='col-sm-8 col-xs-12 col-sm-offset-2 col-xs-offset-0'>
              <div class='section-heading'>
                <h2 class='title'><span>Our</span> Recent Newsletter</h2>
                <p>Check out our updates, our partners latest products and more!</p>
              </div>
            </div>
          </div> <!-- /row -->
          <div class='row'>
            <div class='col-sm-6 col-md-4' v-for="n in NewsLettersBanr" v-bind:key="n.id">

              <div class='re-blog-item'>
                <div class='re-feature-img'>
                  <v-img alt='Features' :src="n.Image" height="200" aspect-ratio="1" />
                </div>
                <div class='caption'>
                  <a :href="'/NewsLetter/' + n.ID">
                    <h4 class='gen-title'>{{ n.Title }}</h4>
                  </a>
                  <p
                    style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                    {{ n.SubTitle }}</p>
                </div>
              
                  <div class='re-time'  :href="'/NewsLetter/' + n.ID">
                    {{ format_date(n.Date) }}
                  </div>
              </div> <!-- /.re-blog-item -->

            </div> <!-- /.col- -->


          </div> <!-- /.row -->
        </div> <!-- /.container -->
      </section> <!-- /.recent-blog -->
      <!-- /Recent Blog AREA END -->


      <!-- Newsletter AREA START -->
      <section class='newsletter section'
        style="background-image: url(https://firebasestorage.googleapis.com/v0/b/websiteincubefzco.appspot.com/o/Others%2F8.png?alt=media&token=83ed6f1a-81b1-4be0-8e6d-c40bfa9eae2e);background-size: cover;">
        <div class='container'>
          <div class='section-heading'>
            <h2 class='title' style="color: white;"><span>Get</span> Newsletter</h2>
          </div>
          <div class='row'>
            <div class='col-xs-12 col-sm-12 col-md-6'>
              <div class='news-content' style="text-align: initial;">
                <p style="color: white;font-weight: bold;">Sign up and stay up to date with your newsletter </p>
              </div>
            </div> <!-- /.col- -->
            <div class=' col-xs-12 col-sm-12 col-md-6'>
              <div>
                <form
                  action='https://safesyntax.us18.list-manage.com/subscribe/post?u=863a11825ade4f42e47ef6e9b&amp;id=2c07d90f22'
                  class='news-form validate' method='post' name='mc-embedded-subscribe-form' target='_blank'>
                  <div class='col-sm-8 col-xs-12'>
                    <div class='form-group'>
                      <div class='sub-mail'>
                        <input class=' form-control' name='EMAIL' placeholder='Your email address' type='email'
                          value='' />
                      </div>
                    </div>
                  </div> <!-- /.col- -->

                  <div class='col-sm-4 col-xs-6 pull-right'>
                    <div class='form-group'>
                      <div class='submit-btn'>
                        <input class='form-control' name='subscribe' type='submit' value='Subscribe!' />
                      </div>
                    </div>
                  </div> <!-- /.col- -->
                </form>
              </div>
            </div> <!-- /.col -->
          </div> <!-- /.row -->
        </div> <!-- /.container -->
      </section> <!-- /.newsletter -->
      <!-- /Newsletter AREA END -->



      <!-- Video AREA START -->

      <!-- /Video AREA END -->


      <!-- Our Client AREA START -->
      <div class='section-heading'>
        <h2 class='title'><span>Our</span> Success Stories
        </h2>
      </div>

      <div class='our-client'>
        <div class='container'>

          <!--<div class="client-slider owl-carousel owl-theme"> -->
          <carousel :per-page="5" :autoplay="true">
            <slide v-for="n in partner" v-bind:key="n.id">
              <div class='item'>
                <div class='logo-each'>
                  <slide>
                    <v-img :src="n.PartnerImage" contain aspect-ratio="1" />
                  </slide>

                </div>
              </div>
            </slide>
          </carousel>
          <!-- /.client-slider -->
        </div> <!-- /.container -->
      </div> <!-- /.our-client -->
      <!-- /Our Client AREA END -->



      <!-- Footer AREA START -->

      <!-- /Footer AREA END -->

      <!-- Go To Top Link -->
      <a class='back-to-top' href='#'><i class='fa fa-angle-up' /></a>
    </section>

	

    <!-- Start Services Area -->


  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import countTo from 'vue-count-to';
// import { db,fb } from '@/db'
$(document).ready(function () {

  $('.popup-btn').on('click', function () {
    $('.video-popup').fadeIn('slow');
    return false;
  });

  $('.popup-bg').on('click', function () {
    $('.video-popup').slideUp('slow');
    return false;
  });

  $('.close-btn').on('click', function () {
    $('.video-popup').fadeOut('slow');
    return false;
  });

});

export default {


  data() {
    return {
      HeadersImgs :['https://firebasestorage.googleapis.com/v0/b/websiteincubefzco.appspot.com/o/Others%2F1.png?alt=media&token=dc001859-4094-4974-ae44-d57540cec026'],
      playerOptions: {
        // videojs options
        muted: "false",
        language: 'en',
        autoplay: true,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: "https://firebasestorage.googleapis.com/v0/b/al-awsat.appspot.com/o/Samsung%204K%20Demo_%20Seven%20Wonders%20of%20the%20World.mp4?alt=media&token=283e3e66-1eba-4384-8976-afc770b65438"
        }],
        poster: "/static/images/author.jpg",
      }
    }
  },
  components: {
    Carousel,
    countTo
  },
  computed: {
    partner() {
      return this.$store.state.Partner
    },
    Products() {
      return this.$store.state.Products
    },
    OurWork() {
      return this.$store.state.OurWork
    },
    NewsLettersBanr() {
      return this.$store.state.NewsLettersBanr
    },
    Events(){
        return this.$store.state.Events
      },
  },
  methods: {
    format_date: function (value) {

      let myDate = new Date(value.seconds * 1000)
      var now = new Date()


      if (!(myDate.getDate() == now.getDate() && myDate.getMonth() == now.getMonth()
        && myDate.getFullYear() == now.getFullYear())) {
        let options = {
          weekday: "long", year: "numeric", month: "short", day: "numeric"
        };
        return myDate.toLocaleDateString("en-us", options)
      }
      else {
        let options = {
          hour: "2-digit", minute: "2-digit"
        };
        return ("Today , " + myDate.toLocaleTimeString("en-us", options))
      }



    },
  },
  mounted() {
    //this.$refs.videoPlayer.play();

  },
  created() {
    this.$store.dispatch('bindPartner');
    this.$store.dispatch('bindProducts');
    this.$store.dispatch('bindOurWork');
    this.$store.dispatch('bindNewsLettersBanar');
    this.$store.dispatch('bindHomeEvents')
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Style the icon bar links */
.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 5px;
}

p {
  text-align: center;
}

.title {
  text-align: center;
  text-transform: uppercase;
}

.video {
  width: 100%;
  height: 400px;
  margin: auto;
  display: block;
  border: none;
}

.popup-btn,
.close-btn {
  width: 100px;
  height: 50px;
  display: block;
  margin: 50px auto;
  cursor: pointer;
  border: 2px solid black;
  background-color: white;
}

.popup-btn:hover {
  color: white;
  background-color: black;
}

.video-popup {
  display: none;
  width: 100%;
  height: 100vh;
  margin: auto;
  position: fixed;
  top: 0;
  box-shadow: 10px 10px 10px 10px black;
}

.popup-bg {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
  position: absolute;
}

.popup-content {
  background: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  height: auto;
}

.popup-title {
  padding: 30px;
  margin: 0 auto;
  color: red;
  text-transform: uppercase;
}</style>
