<template>
  <div>
    <section class="page-head page-bg" :style="{ 'background-image': `url(${Distribution[0].D_Image})` }">
      <div class="container">
        <h3 class="page-heading"> </h3>
        <div class="justify-center">
          <span> </span>
        </div>
      </div> <!-- /.container -->
    </section> <!-- /.page-head -->
    <!-- /Page Header AREA END -->

    <!-- Our Services AREA START -->
    <section class="our-service section">
      <div class="container">
        <div class="row">
          <div class="col-sm-8 col-xs-12 col-sm-offset-2 col-xs-offset-0">
            <center>
            <h2 class="title">
              {{ Distribution[0].D_Name }}
            </h2>
            </center>
            <div class="elementor-text-editor elementor-clearfix">
              <p>{{ Distribution[0].D_Descriptions }}</p>
            </div>
          </div>
        </div> <!-- /.row -->

        <div v-if="Distribution[0].IsSoftware === true">
          <h2 class="title">
            <center><span><b> Solutions</b></span></center>
          </h2>
        </div>
        <div v-else>
          <h2 class="title">
            <center><span><b> Products</b></span></center>
          </h2>

        </div>


        <template>

          <div v-if="Distribution[0].IsSoftware === true">

            <section class='portfolios section'>
              <div class='container'>
                <v-row>
                  <div class="portfolio-details" style="width: 100%;">
                    <div>
                      <div class="grid-item col-md-4 col-xs-4" style="min-height: 250px;" v-for="n in Products"
                        v-bind:key="n.id">
                        <div class="portfolio-item">
                          <v-img :src="n.Images[0]" alt="Gallery" />

                          <div class="port-hover">
                            <div class="port-content">

                              <p>{{ n.Description }}</p>

                              <router-link :to="'/ProductDetails/' + n.ItemName">

                                <a data-fancybox="group" class="port-icon">
                                  <img class="hidden-thumbnail" :src="n.Images[0]" alt="Thumbnail">
                                  <img src="assets/images/icons/plus-btn.png" alt="Icon">
                                </a>
                              </router-link>
                            </div> <!-- /.port-content -->
                          </div> <!-- /.port-hover -->
                        </div><!-- /.portfolio-item -->
                        <center>
                          <a>
                            <h4>{{ n.ItemName }}</h4>
                          </a>
                        </center>
                      </div> <!-- /.grid-item -->


                    </div><!-- /.portfolio-container -->
                  </div> <!-- /.portfolio-details -->
                </v-row> <!-- /.row -->
              </div>
            </section>

          </div>
          <v-card v-else>
            <v-tabs color="deep-purple accent-4" right v-model="selectedTab">
              <v-tab v-for="i in Categories" :key="i.id" cols="12" md="3" @click="getMenuItem(i)" :id="i">
                {{ getcategoryName(i) }}</v-tab>
              <v-tab-item v-for="n in 6" :key="n">
                <v-container fluid>
                  <v-row>
                    <v-col v-for="i in Products" :key="i.id" cols="12" md="3">
                      <v-hover close-delay="50" v-slot="{ hover }">
                        <v-card class="mx-auto" max-width="400" :class="{ 'on-hover': hover }"
                          :elevation="hover ? 12 : 2">
                          <v-img :src="i.Images[0]" contain aspect-ratio="1.5"></v-img>
                          <v-col>
                            <div class="row">
                              <div class="col-12 text-truncate">
                                <H5
                                  style="display: block;white-space: nowrap;width: 12em;overflow: hidden;text-overflow: ellipsis;">
                                  {{ i.ItemName }}</H5>
                              </div>
                            </div>
                            <v-divider inset></v-divider>
                            <v-row style="margin: 5px;">
                              <v-btn class="ma-2" outlined color="blue-grey" :to="'/ProductDetails/' + i.ItemName">
                                Read More
                              </v-btn>
                              <v-btn class="ma-2" text icon color="blue lighten-2" :href="`${i.PDFURL}`" target="_blank">
                                <v-icon>mdi-file-pdf</v-icon>
                              </v-btn>
                            </v-row>
                          </v-col>
                        </v-card>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </template>
      </div><!-- container -->
    </section> <!-- /.our-service -->
    <!-- /Our Services AREA END -->
  </div>
</template>
<script>
import { db } from '@/db'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'app',
  data() {
    return {
      dialog: false,
      partnerID: '',
      Categories: [],
      Products: [],
      selectedTab: null
    }
  },
  created() {
    
    var context = this;
    this.partnerID = this.$route.params.PartnerID
    this.$store.dispatch('bindDistribution', this.partnerID).then((res) => {
      
      context.$store.dispatch('bindProductPartner', context.Distribution[0].D_ID).then((res) => {
        context.getMenuItem('');

      })
    });

  },
  computed: {
    Distribution() {
      
      return this.$store.state.Distribution
    },
  },
  watch: {
    '$route.params.PartnerID'(newId, oldId) {
      
      var context = this;
      this.Categories = []
      this.partnerID = newId
      this.$store.dispatch('bindDistribution', newId).then((res) => {
        
        context.$store.dispatch('bindProductPartner',context.Distribution[0].D_ID).then((res) => {
          context.getMenuItem('');
        })

      });

      //this.getMenuItem('')
    }
  },
  methods: {
    getcategories() {
      
      for (let i = 0; i < this.Products.length; i++) {
        if (this.Categories.includes(this.Products[i].type) !== true && this.Products[i].Distribution.D_Name === this.partnerID)
          this.Categories.push(this.Products[i].type)
      }
    },
    getcategoryName: async function (categoryID) {

      var context = this;
      await db.collection('Categories')
        .doc(categoryID)
        .get()
        .then(async snapshot => {
          let x = await snapshot.data().C_Name
          document.getElementById(categoryID).innerHTML = x
        })
    },
    getMenuItem(tabid) {
      
      this.Products = []
      if (tabid === '') {
        
        var context = this
        db.collection('Product').where('D_ID', '==', context.Distribution[0].D_ID).get()
          .then(snapshots => {
            
            if (snapshots.docs.length === 0) {
              this.Products = []
              return []
            }
            this.Products = snapshots.docs.map(doc => ({ ...doc.data(), id: doc.ID }))
            context.getcategories()
            context.getMenuItem(context.Categories[0])
          })
      } else {
        var query = db.collection('Product').where('D_ID', '==', this.Distribution[0].D_ID ).get()
          .then(snapshots => {
            
            if (snapshots.docs.length === 0) {
              this.Products = []
              return []
            }
            this.Products = snapshots.docs.map(doc => ({ ...doc.data(), id: doc.ID })).filter(function (e) {
              return e.type === tabid;
            })
            
            context.getcategories();

          })
      }
    }
  }

}
</script>

<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)
  >.v-card__text
    color: #000
</style>